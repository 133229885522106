import React, { useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"
import { setLoading } from "../state/barelyTypography"
import { updateVariantModal } from "../state/cart"
import TweetEmbed from "react-tweet-embed"

import SEO from "../components/seo"
import Layout from "../components/layout"

import bt_logo from "../images/barely-typography/BaRELY.TYPOGRAPhY-white.png"
import bt_model_img from "../images/barely-typography/PIJ-Clothing-BarelyType-Logo-Mock01.jpg"
import bt_world_img from "../images/barely-typography/PIJ-World-White.png"
import bt_chain_img from "../images/barely-typography/PIJ-logomark-fill-white.png"

const BarelyTypography = ({ loading, dispatch }) => {
  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 500)
  })
  return (
    <Layout>
      <Container fluid className="bt_body_container">
        <SEO title="Barely Typography" />
        <Row className="body-row d-none d-md-flex position-fixed">
          <Col
            className="position-fixed logo-col-container d-none d-md-block"
            md={{ size: 1 }}
          >
            <Link to="/">
              <img
                className="logomark"
                src="../../static/logos/PIJ-logomark-fill-white.svg"
                alt="pij chain"
              />
            </Link>
          </Col>
          <Col className="bt_logo_container" md={{ size: 10 }}>
            <img src={bt_logo} alt="bg_logo" />
          </Col>
        </Row>
        <Row className="bt_content_row">
          <Col
            md={{ size: 4, offset: 2 }}
            className="d-flex flex-column align-items-center"
          >
            <img
              className="bt_world_img"
              src={bt_world_img}
              alt="bt_world_img"
            />

            <div className="bt_twitter_one">
              <TweetEmbed id="1225917261308841985" />
              <button
                onClick={() =>
                  dispatch(
                    updateVariantModal({
                      isOpen: true,
                      productId: "black-hoodie",
                    })
                  )
                }
                className="bt_buy_button"
              >
                Buy Now
              </button>
            </div>
          </Col>
          <Col
            md={{ size: 4 }}
            className="d-flex flex-column align-items-center"
          >
            <div className="bt_model_img">
              <img className="" src={bt_model_img} alt="bt_model_img" />
              <button
                onClick={() =>
                  dispatch(
                    updateVariantModal({
                      isOpen: true,
                      productId: "barely-hoodie",
                    })
                  )
                }
                className="bt_buy_button"
              >
                Buy Now
              </button>
            </div>

            <img
              className="bt_chain_img"
              src={bt_chain_img}
              alt="bt_chain_img"
            />
            <TweetEmbed className="bt_twitter_two" id="1225923945007919104" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default connect(
  state => ({
    loading: state.barelyTypography.loading,
  }),
  null
)(BarelyTypography)
